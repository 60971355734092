import { useTheme } from "@material-ui/core";
import WarningIcon from '@material-ui/icons/Warning';

interface IProps {
    warning: string;
}

export const WarningComponent = ({ warning }: IProps) => {
    const theme = useTheme();

    return (
        <div className="df-row-ac" style={{ color: theme.palette.warning.main }}>
            <WarningIcon style={{ marginRight: 8 }} />
            <div style={{ fontSize: '18px' }}>{warning}</div>
        </div>
    );
}