import { LinearProgress } from "@material-ui/core";
import { Plugin, Viewer } from "@react-pdf-viewer/core";
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

interface IProps {
    url: string;
    plugins: Plugin[];
}

export const PdfViewer = ({ url, plugins }: IProps) => {
    return (
        <div className="df-col-ac">
            <Viewer
                fileUrl={url}
                renderLoader={(percentage: number) => (
                    <div style={{ width: '240px' }}>
                        <LinearProgress value={Math.round(percentage)} variant="determinate" />
                    </div>
                )}                
                defaultScale={1.25}
                plugins={plugins} />
        </div>
    );
}

