
import React, { useCallback, useContext } from 'react';
import { ApiContext } from '../context/ApiContext';
import { LoaderContext } from '../context/LoaderContext';
import { useApiErrorHandling } from './useApiErrorHandling';

export const useApi = (): [() => string, (val: string) => void, React.Dispatch<React.SetStateAction<any>>] => {
    const { load, clear } = useContext(LoaderContext);
    const setLoad = useCallback(() => load()
        // eslint-disable-next-line
        , []);
    const clearLoad = useCallback((val: string) => clear(val)
        // eslint-disable-next-line
        , []);
    const apiContext = useContext(ApiContext);

    return [setLoad, clearLoad, useApiErrorHandling(apiContext.strings, apiContext.redirect, apiContext.showError)];
}