import { createMuiTheme, Theme } from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";

export const theme: Theme = createMuiTheme({
    palette: {
        primary: {
            main: '#1976d2'
        },
        secondary: {
            main: 'rgb(222,25,43)'
        }
        // primary: {
        //     main: '#1A114E'
        // },
        // secondary: {
        //     main: '#8158FC'
        // }
    },
    overrides: {
        MuiTypography: {
            h1: {
                fontWeight: 400,
                fontSize: '24px',
            },
            h2: {
                color: blueGrey[600],
                textTransform: 'uppercase',
                fontSize: '11px',
                fontWeight: 500,
            },
            // body2: {
            //     fontSize: '12px',
            //     letterSpacing: '-0.04px'
            // },
            gutterBottom: {
                marginBottom: 8,
            },
            colorTextSecondary: {
                // color: blueGrey[600],
            }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
            }
        },
        MuiCardHeader: {
            title: {
                fontSize: '16px',
                fontWeight: 500,
            }
        },
        MuiInputBase: {
            input: {
                fontSize: '14px',
                lineHeight: '16px',
            },
        },
        MuiFormLabel: {
            root: {
                fontSize: '14px'
            }
        }
    }
});