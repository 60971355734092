
import React from 'react';
import { useSnackbarNotify } from '../hooks/useSnackbarNotify';
import { ApiContext } from './ApiContext';
import { IApiErrorStrings } from './localization/IApiErrorString';

interface IProps {
    children: any;
    strings: IApiErrorStrings;
    onRedirect: () => void;
}

export const ApiProvider = ({ children, strings, onRedirect }: IProps) => {
    const notify = useSnackbarNotify();

    return (
        <ApiContext.Provider
            value={{
                strings: strings,
                showError: (error: string) => notify(error, 'error'),
                redirect: onRedirect,
            }}>
            {children}
        </ApiContext.Provider>
    );
}