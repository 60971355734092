import { Button, Typography } from '@material-ui/core';
import { Logo } from './logo/Logo';
import { clearCacheAndReloadApplication } from '../framework/utils/clearCacheAndReloadApplication';

export const MustUpdateWall = () => {
    return (
        <div className="df-col-ac stretch-ver jc-c">
            <Logo style={{ marginBottom: '20px', width: '400px' }} />
            <Typography variant="h5" style={{ marginBottom: '30px' }}>{`An update is available,\nclick below to refresh this application`}</Typography>
            <Button variant="contained" color="primary" onClick={clearCacheAndReloadApplication}>{`Update`}</Button>
        </div>
    );
}

