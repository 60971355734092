import { AppBar } from "@material-ui/core";
import { Logo } from './logo/Logo'

interface IProps {
    children: any;
    styleChildren?: React.CSSProperties;
}

export const Page = ({ children, styleChildren }: IProps) => {
    return (
        <div className="fg1 df-col stretch-ver">
            <AppBar position="sticky" style={{ background: 'white', borderBottom: '1px solid lightgray', height: '80px', color: 'black' }} elevation={0}>
                <div className="df-row-ac fg1 stretch-ver" style={{ padding: 16 }}>
                    <Logo />
                    <div style={{ marginLeft: 16, fontSize: '24px' }}>Euro-Sprinters CMR tool</div>
                </div>
            </AppBar>
            <div className="stretch-ver" style={{ padding: 16, ...styleChildren }}>
                {children}
            </div>
        </div>
    );
}