import { AppBar, Dialog, DialogContent, DialogProps, IconButton, Slide, Toolbar } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import React from 'react';
import { Logo } from '../logo/Logo';
import { base64ToBlobUrl } from './base64ToBlobUrl';
import { DownloadComponent } from './DownloadComponent';
import { PdfViewer } from './PdfViewer';
import { ZoomComponent } from './ZoomComponent';

const Transition = React.forwardRef(function Transition(props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<unknown>,) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps extends DialogProps {
    close: () => void;
    content: string | undefined;
}

export const PdfViewerDialog = ({ content, close, ...rest }: IProps) => {
    const zoomPluginInstance = zoomPlugin();
    const getFilePluginInstance = getFilePlugin();

    return (
        <Dialog
            fullScreen
            TransitionComponent={Transition}
            scroll="paper"
            {...rest}>
            {rest.open &&
                <>
                    <AppBar style={{ position: 'relative' }} color="secondary">
                        <Toolbar className="df-row-ac">
                            <div className="fg1 fb0">
                                <Logo style={{ width: '40px', height: '40px' }} />
                            </div>
                            <div>
                                <ZoomComponent
                                    plugin={zoomPluginInstance} />
                            </div>
                            <div className="df-row fg1 fb0 jc-e">
                                <DownloadComponent
                                    plugin={getFilePluginInstance} />
                                <IconButton edge="end" color="inherit" onClick={close} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <DialogContent
                        style={{
                            padding: 0
                        }}>
                        {content &&
                            <PdfViewer
                                url={base64ToBlobUrl(content)}
                                plugins={[zoomPluginInstance, getFilePluginInstance]} />
                        }
                    </DialogContent>
                </>
            }
        </Dialog>
    );
}
