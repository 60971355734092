import { Card, CardContent, CardHeader, Divider, List } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router";
import { useApiEffect } from "../../framework/hooks/useApiEffect";
import { isLast } from '../../framework/utils/array/isLast';
import { cmrQuery_query } from "../../gen/ApiClient";
import { Page } from '../Page';
import { CmrDocumentListItem } from './CmrDocumentListItem';
import { ErrorComponent } from './ErrorComponent';
import { IRouteParamsWithRef } from "./IRouteParamsWithRef";
import { PodDocumentListItem } from './PodDocumentListItem';
import { WarningComponent } from './WarningComponent';

export const CmrPage = () => {
    const { ref } = useParams<IRouteParamsWithRef>();
    const [cmrResponse] = useApiEffect(cmrQuery_query, ref);

    return (
        <Page styleChildren={{
            display: 'flex',
            justifyContent: 'center',
            height: 'fit-content',
            marginTop: 32
        }}>
            <>
                {cmrResponse && cmrResponse.hasError &&
                    <ErrorComponent error={cmrResponse.error!} />
                }
                {cmrResponse && cmrResponse.hasError === false && cmrResponse.hasWarning &&
                    <WarningComponent warning={cmrResponse.warning!} />
                }
                {cmrResponse && cmrResponse.isSuccess &&
                    <Card style={{ width: 'fit-content' }}>
                        <CardHeader title="CMR document(s)" />
                        <CardContent>
                            {cmrResponse.hasCmrDocuments &&
                                <List disablePadding>
                                    {cmrResponse.cmrDocuments!.map(t =>
                                        <React.Fragment key={t.id}>
                                            <CmrDocumentListItem doc={t} />
                                            {isLast(t, cmrResponse.cmrDocuments) === false && <Divider />}
                                        </React.Fragment>
                                    )}
                                </List>
                            }
                            {cmrResponse.hasPodDocuments &&
                                <List disablePadding>
                                    {cmrResponse.podDocuments!.map(t =>
                                        <React.Fragment key={t.fileId}>
                                            <PodDocumentListItem doc={t} />
                                            {isLast(t, cmrResponse.podDocuments) === false && <Divider />}
                                        </React.Fragment>
                                    )}
                                </List>
                            }
                        </CardContent>
                    </Card>
                }
            </>
        </Page>
    );
}
