import { AxiosInstance, AxiosRequestConfig } from "axios";
import { apiUrl } from "../constants";
import { FrameworkDefaultAxiosInstance } from "../framework/xhr/FrameworkDefaultAxiosInstance";

export const DefaultAxiosInstance = (config?: AxiosRequestConfig): AxiosInstance => {
    const instance = FrameworkDefaultAxiosInstance(apiUrl, config);
    instance.interceptors.request.use(config => {
        config.withCredentials = true;
        config.headers.Authorization = 'X-CMR-ES-Api-Key 5c68a007-7bff-47d2-80bf-91008ba9f8c1';
        return config;
    });
    return instance;
}

