import { Button, ListItem, ListItemIcon, Typography } from "@material-ui/core";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import React, { useState } from "react";
import { useApiCall } from "../../framework/hooks/useApiCall";
import { formatDate } from '../../framework/utils/date/formatDate';
import { cmrDocumentsQuery_content, cmrDocumentsQuery_download, ICmrDocument } from "../../gen/ApiClient";
import { useDownload } from '../../gen/useDownload';
import { usePdfViewer } from "../pdf/context/usePdfViewer";

interface IProps {
    doc: ICmrDocument;
    style?: React.CSSProperties;
}

export const CmrDocumentListItem = ({ doc, style }: IProps) => {
    const [download, isDownloading] = useDownload(cmrDocumentsQuery_download);
    const view = usePdfViewer();
    const downloadContent = useApiCall(cmrDocumentsQuery_content);
    const [isDownloadingContent, setIsDownloadingContent] = useState<boolean>(false);

    const onView = async () => {
        setIsDownloadingContent(true);
        const r = await downloadContent(doc.id);
        setIsDownloadingContent(false);
        if (r.isSuccess) {
            view(r.result);
        }
    }

    return (
        <ListItem style={style} disableGutters>
            <ListItemIcon style={{ minWidth: '35px' }}>
                <DescriptionOutlinedIcon />
            </ListItemIcon>
            <div className="df-row-ac">
                <Typography variant="caption" color="textSecondary" style={{ marginRight: 8 }}>{formatDate(doc.created)}</Typography>
                <Typography variant="body2" noWrap display="block">{doc.name}</Typography>
            </div>
            <div className="fg1" style={{ minWidth: '16px' }}></div>
            <div className="df-row-ac">
                <Button variant="text" color="secondary" startIcon={<VisibilityOutlinedIcon />} disabled={doc.isPdf === false || isDownloadingContent} onClick={onView}>View</Button>
                <Button variant="text" color="secondary" startIcon={<GetAppOutlinedIcon />} disabled={isDownloading} onClick={() => download(doc.id)}>Download</Button>
            </div>
        </ListItem>
    );
}