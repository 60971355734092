import React, { useState, useEffect } from 'react';
import { useApiEffect } from '../framework/hooks/useApiEffect';
import { meta_build } from '../gen/ApiClient';
import { build } from '../build';
import { MustUpdateWall } from './MustUpdateWall';

export const AppUpdateWall: React.FC = ({ children }: any) => {
    const [serverBuild] = useApiEffect(meta_build);
    const [isOutdated, setIsOutdated] = useState<boolean>(false);
  
    useEffect(() => {
      if (serverBuild && (serverBuild > build)) {
        setIsOutdated(true);
      }
    }, [serverBuild])
  
    if (isOutdated) {
      return <MustUpdateWall />
    } else {
      return children;
    }
  }