import { useTheme } from "@material-ui/core";
import ErrorIcon from '@material-ui/icons/Error';

interface IProps {
    error: string;
}

export const ErrorComponent = ({ error }: IProps) => {
    const theme = useTheme();
    
    return (
        <div className="df-row-ac" style={{ color: theme.palette.error.main }}>
            <ErrorIcon style={{ marginRight: 8 }} />
            <div style={{ fontSize: '18px' }}>{error}</div>
        </div>
    );
}