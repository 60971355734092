import React, { useEffect, useMemo, useState } from 'react';
import { v1 as uuid } from 'uuid';
import { LoaderContext } from './LoaderContext';

interface ILoaderProviderProps {
    children: any;
    loader: JSX.Element;
}

export const LoaderProvider = ({ children, loader }: ILoaderProviderProps) => {
    const [loadIds, setLoadIds] = useState<string[]>([]);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const isLoading = useMemo(() => loadIds.length > 0, [loadIds]);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        let cancelled = false;

        if (isLoading && cancelled === false) {
            timer = setTimeout(() => {
                setShowLoader(true);
            }, 1000);
        }
        return () => {
            clearTimeout(timer);
            cancelled = true;
            setShowLoader(false);
        }
    }, [isLoading]);

    return (
        <LoaderContext.Provider
            value={{
                load: () => {
                    const uid = uuid();
                    // loadIds.current.push(uid);
                    setLoadIds(prev => [...prev, uid]);
                    // hitTrigger();
                    return uid;
                },
                clear: (id: string | undefined) => {
                    // loadIds.current = loadIds.current.filter(t => t !== id);
                    // hitTrigger();
                    setLoadIds(prev => prev.filter(t => t !== id));
                },
                count: loadIds.length
            }}>
            <div className="stretch-ver stretch-hor" style={{ background: 'white' }}>
                {showLoader === true && loader}
                {children}
            </div>
        </LoaderContext.Provider>
    );
}
