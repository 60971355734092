import { Error } from './Error';

export const ErrorPage = () => {
    return (
        <div
            className="stretch-ver stretch-hor df-col-ac fg1 jc-c">
            <Error
                style={{ background: 'white' }} />
            <div
                style={{
                    whiteSpace: 'pre-wrap',
                    textAlign: 'center',
                    fontSize: '30px',
                    fontWeight: 700,
                    marginTop: '32px',
                }}>
                {`Whoops, something went wrong\nThe provided url is invalid`}
            </div>
        </div>
    );
}