import { Backdrop } from '@material-ui/core';
import loader from './loader.gif';

export const Loader = () => {
    return (
        <Backdrop
            style={{ zIndex: 25000, backgroundColor: 'rgba(255, 255, 255, 0.85' }}
            open={true} >
            <img src={loader} alt="loading..." />
        </Backdrop>
    );
}