import React from 'react';
import { ReactComponent } from './logo.svg';

interface IProps {
    className?: string,
    style?: React.CSSProperties,
}

export const Logo = ({ className, style }: IProps) => {
    return (
        <ReactComponent
            className={className}
            style={style} />
    );
}