import { Worker } from "@react-pdf-viewer/core";
import React, { useState } from 'react';
import { PdfViewerDialog } from '../PdfViewerDialog';
import { PdfViewerDialogContext } from './PdfViewerDialogContext';

export const PdfViewerDialogProvider = ({ children }: any) => {
    const [content, setContent] = useState<string>();

    const open = (content: string) => {
        setContent(content);
    }

    return (
        <PdfViewerDialogContext.Provider
            value={{
                open: open
            }}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                <PdfViewerDialog
                    content={content}
                    open={Boolean(content)}
                    close={() => setContent(undefined)} />
                {children}
            </Worker>
        </PdfViewerDialogContext.Provider>
    );
};
