import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ApiProvider } from '../framework/context/ApiProvider';
import { LoaderProvider } from '../framework/context/LoaderProvider';
import { enApiErrorStrings } from '../framework/context/localization/enApiErrorStrings';
import * as routes from '../routes';
import { AppUpdateWall } from './AppUpdateWall';
import { CmrPage } from './cmr/CmrPage';
import { ErrorPage } from './error/ErrorPage';
import { Loader } from './loader/Loader';
import { SnackbarWrapper } from './SnackbarWrapper';
import { theme } from './theme';
import { PdfViewerDialogProvider } from '../app/pdf/context/PdfViewerDialogProvider';

const App: React.FC = () => {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <SnackbarWrapper>
          <BrowserRouter>
            <LoaderProvider loader={<Loader />}>
              <ApiProvider strings={enApiErrorStrings} onRedirect={() => { }} >
                <AppUpdateWall>
                  <PdfViewerDialogProvider>
                    <Switch>
                      <Route exact path={routes.CmrRoute} component={CmrPage} />
                      <Route exact path={routes.ErrorRoute} component={ErrorPage} />
                      <Redirect path="/" to={routes.ErrorRoute} />
                    </Switch>
                  </PdfViewerDialogProvider>
                </AppUpdateWall>
              </ApiProvider>
            </LoaderProvider>
          </BrowserRouter>
        </SnackbarWrapper>
      </ThemeProvider>
    </>
  );
}

export default App;
